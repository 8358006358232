import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import {useTranslation} from "react-i18next";
import "../translations/i18n";
import ReactTooltip from 'react-tooltip';
import { getSocials} from "../utils/Common";
import Link from "gatsby-link";
import {StaticImage} from "gatsby-plugin-image";
import ErpModules from "../components/layout/ErpModules";

const social = getSocials();

const SeproERP = () => {
    const {t} = useTranslation();
    const d = new Date();
    let year = d.getFullYear();
    return (
        <Layout pageTitle={t("sepro_solution") + " - " + t("sepro_erp")}>
            <div className="min-h-full bg-kayloo">
                <NavBar active={'solution'} page="solution" label={t("sepro_solution")}/>
                <div className="px-4 py-8 md:py-8 bg-sepro-erp-header">
                    <div className="max-w-8xl mb-20">
                        <div className="relative flex flex-col max-w-md py-3 text-left lg:mb-32">
                            <h1 className="max-w-3xl text-3xl font-bold tracking-tight lg:text-5xl space-x-6 text-gray-900 font-family-athena-title">{t("sepro_erp")}</h1>
                            <p className="mt-2 text-sm md:text-base text-gray-900 font-family-athena-p">{t("sepro_erp_msg_1")}</p>
                        </div>
                    </div>
                </div>

                <div className="-mt-20 sm:pb-40 bg-kayloo-2">
                    <div
                        className="relative flex flex-col  max-w-3xl px-6 sm:py-8 mx-auto sm:my-10 lg:px-0">
                        <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-100 lg:text-5xl mt-8 md:mt-20 text-center">{t("why")}
                            <span className="text-cyan-500">{t("sepro_erp")} ?</span></h1>
                        <p className="max-w-full mt-10 text-sm md:text-base text-gray-400 text-justify">{t("sepro_erp_msg_2")}</p>
                        <p className="max-w-full text-sm md:text-base text-gray-400 text-justify ">{t("sepro_erp_msg_3")}</p>
                        <p className="max-w-full text-sm md:text-base text-gray-400 text-left">{t("sepro_erp_msg_4")}</p>
                        <p className="max-w-full text-sm md:text-base text-gray-400 float-left">{t("sepro_erp_msg_5")}</p>
                        <p className="max-w-full text-sm md:text-base text-gray-400 ">{t("sepro_erp_msg_7")}</p>
                        <p className="text-sm md:text-base text-gray-400 text-left">{t("sepro_erp_msg_8")}</p>
                        <p className="max-w-full text-sm md:text-base text-gray-400 text-justify mb-36 md:mb-20">{t("sepro_erp_msg_9")}</p>
                    </div>
                </div>
                <div
                    className="relative flex flex-col items-center max-w-3xl px-6  mx-auto text-center sm:my-15 lg:px-0 md:-mt-40 -mt-20 ">
                    <StaticImage src={"../../static/erp/erp-image.png"} alt={t("sepro_erp")}
                                 className="object-center object-cover md:-mt-36 md:max-w-7xl"/>
                </div>
                {/*mission SEPRO ERP*/}
                <div className="relative flex flex-col items-center px-4">
                    <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-900 lg:text-5xl mt-10 md:mt-20">{t("missions_de")}
                        <span className="text-cyan-500">{t("sepro_erp")}</span></h1>
                    <p className="max-w-3xl mt-10 text-sm md:text-base text-gray-400 text-justify mb-20">{t("sepro_erp_missions")}</p>
                    <div className="grid grid-cols-1 gap-4 items-start md:grid-cols-2 lg:grid-cols-2 lg:gap-8 md:text-xl text-center">
                        <div className="grid grid-cols-1 mb-2">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-1 xl:col-start-1  lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../static/erp/icons/icon1.svg"}
                                                alt={t('mission_erp_1')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 md:px-4">
                                    <h2 className="max-w-1xl text-xl md:text-2xl lg:text-xl font-bold text-cyan-500 mb-8">{t("mission_erp_1")}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('desc_mission_erp_1')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 mb-2">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-1 xl:col-start-1  lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../static/erp/icons/icon2.svg"}
                                                alt={t('mission_erp_2')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 md:px-4">
                                    <h2 className="max-w-1xl text-xl lg:text-xl md:text-2xl   font-bold text-cyan-500 mb-8">{t("mission_erp_2")}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('desc_mission_erp_2')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 mb-2">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-1 xl:col-start-1  lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../static/erp/icons/icon3.svg"}
                                                alt={t('mission_erp_3')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 md:px-4">
                                    <h2 className="max-w-1xl text-xl lg:text-xl md:text-2xl font-bold text-cyan-500 mb-8">{t("mission_erp_3")}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('desc_mission_erp_3')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 mb-2">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-1 xl:col-start-1  lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../static/erp/icons/icon4.svg"}
                                                alt={t('mission_erp_4')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 md:px-4">
                                    <h2 className="max-w-1xl text-xl md:text-2xl lg:text-xl font-bold text-cyan-500 mb-8">{t("mission_erp_4")}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('desc_mission_erp_4')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 mb-2">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-1 xl:col-start-1  lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../static/erp/icons/icon4.svg"}
                                                alt={t('mission_erp_5')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 md:px-4">
                                    <h2 className="max-w-1xl text-xl lg:text-xl md:text-2xl font-bold text-cyan-500 mb-8">{t("mission_erp_5")}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('desc_mission_erp_1')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 mb-2">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-1 xl:col-start-1  lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../static/erp/icons/icon4.svg"}
                                                alt={t('mission_erp_6')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 md:px-4">
                                    <h2 className="max-w-1xl text-xl lg:text-xl  md:text-2xl lg:text-center font-bold text-cyan-500 mb-8">{t("mission_erp_6")}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('desc_mission_erp_6')}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* End missions SEPRO ERP*/}
                {/*<!--> module disponible>*/}
                <div className="relative flex flex-col items-center px-4 -mt-20 md:pb-20 pt-10 md:pt-20">
                    <h1 className="max-w-3xl text-3xl font-bold tracking-tight text-gray-900 lg:text-5xl mt-20">{t("les")}{" "}
                        <span className="text-cyan-500">{t("modules")} </span>{t("disponibles")}</h1>
                    <p className="max-w-3xl mt-5 md:mt-10 text-sm md:text-base text-gray-400 text-justify mb-8 md:mb-20">{t("sepro_module_dispo_text")}</p>
                    <ErpModules/>
                </div>
                {/*<!--> end module sepro erp>*/}

                {/*<!--> start you application>*/}
                <div className="bg-sepro-erp-footer">
                    <div className="">
                        <div className="pt-5 md:pt-28 md:pb-96 ">
                            <div
                                className="relative flex flex-col items-center max-w-4xl px-6 py-3 mx-auto text-center sm:py-6 lg:px-0 mt-10 md:mb-32">
                                <h1 className="max-w-2xl text-3xl font-bold tracking-wide text-white lg:text-5xl uppercase">{t("your_application")}</h1>
                                <h1 className="max-w-2xl text-3xl font-bold tracking-wide text-cyan-500 lg:text-5xl uppercase">{t("of_management")}</h1>
                                {/*<!--p className="mb-14 md:mb-20 text-base md:text-2xl text-gray-200 list-disc text-justify">
                    <span className="mt-2 list-iteme">{t("sepro_erp_msg_6")}</span>
                  </p-->*/}
                                <Link
                                    to={'/contact'}
                                    className="inline-block px-8 py-3 mt-8 text-sm md:text-base font-medium bg-cyan-500 border border-transparent text-slate-100 hover:bg-teal-600 uppercase mb-48"
                                >
                                    {t("contact_us")}
                                </Link>
                            </div>
                        </div>
                        <footer className="bg-transparent">
                            <div
                                className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                                <div className="mt-8 flex justify-center space-x-6">
                                    {social.map((item) => (
                                        <a key={item.name} href={item.href}
                                           className="text-gray-400 hover:text-gray-300" target="_blank"
                                           rel="noreferrer">
                                            <span className="sr-only">{item.name}</span>
                                            <item.icon className="h-10 w-10" aria-hidden="true"/>
                                        </a>
                                    ))}
                                </div>
                                <p className="mt-8 text-center text-sm text-gray-400">&copy; {year} {t("copyright")}</p>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
            <ReactTooltip/>
        </Layout>
    );
}

export default SeproERP;
